.bg-pizza{
    background-image: url(../../asset/pizza-ge61362414_1920.jpg);
    width: 100%;
    height: 400px;
    background-position: center;
}

.bg-pizza p{
    color:#fff;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
}

.parent-container-panier{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1%;
    padding: 2%;
}
.container-bouton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1%;
    padding: 2%;
}
.container-panier{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
}
.row{
    display: flex;
    width: 100%;
    height: 15%;
    align-items: center;
    border: 1px solid black;
}
.titre{
    width: 30%;
}
.body{
    width: 35%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.footer{
    width: 20%;
}

.button-panier{
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-panier{
    width: 20%;
    height: 20%;
}

.btn-commande{
    padding-left: 1%;
    padding-right: 1%;
    padding: 2%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}