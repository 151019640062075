.container-bg-carte{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.btn-taille{
    display: flex;
    align-items: center;
    font-size: 140%;
    width: 30%;
}

.carte-center{
    width: 90%;
    height: 100vh;
}

.scrollable-container {
    height: 800px;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
}
  
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 500px;
    width: 250px;
}
  
.card img {
    width: 250px;
    height: 250px;
    object-fit: cover;
}
  
.card h2 {
    margin: 10px 0;
}

.card_container{
    height: 100%;
    width: 100%;
}

.div_body{
    height: 60%;
}

.div_header{
    height: 19%;
}

.div_footer{
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}
.div_footer_button{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.icon{
    width: 60%;
    height: 60%;
    color:rgba(0, 0, 0, 0.833);
}

.cart{
	cursor: pointer;
    width: 10%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid black;
    /* padding: 2%; */
    margin: 1%;
}
.nav-icon{
    width: 45%;
    height: 35%;
    color: burlywood;
}

.cart-item {
    /* height: 100px; */
    /* width: 100px; */
    border: 1px solid black;
    display: inline-block;
    text-align: center;
  }

.cart-header{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	height: 10%;
	width: 100%;
	border-bottom:1px solid black;
}
.icon-close{
	width: 15%;
}
.btn-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}