.planning{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    border-top: 1px solid #ccc;

}
.planning__container{
    height: 50%;
    overflow-y: scroll;
}

table{
    /* width: 100%; */
    /* height: 100%; */
    border-collapse: collapse;
}

th, td{
    border: 1px solid black;
    padding: 5px;
}

tr:hover{
    background-color: #f2f2f2;
}
.planning__container__table__td__scrollable{
    overflow-y: scroll;
    display: grid;
    height: 80px;
}