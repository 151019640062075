.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1%;
    border: 1px solid black;
}

.container-resume{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 49%;
    height: 49%;
}

.container-form-validation{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bold-text {
  font-weight: bold;
}