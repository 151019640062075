.planning_container_week{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height:60vh;
    border-top: 1px solid #ccc;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    overflow-y: scroll;
}
.planning__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    border-top: 1px solid #ccc;
}

.planning_card{
    display: flex;
    flex-direction: column;
    width: 20%;
    border: 1px solid black;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 1px;
    margin-right:1px;
}

.planning_card_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid black;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #f2f2f2;
}
.planning_card_body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #ccc;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: #f2f2f2;
    overflow-y: auto;
    height: max-content;
}

.display_pizza_rerserved{
    cursor: pointer;
    height: 45px;
    border-radius: 5px;
}
.display_pizza_rerserved:hover{
    background: #ccc;
}


/* popup  */
.popup{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup_container{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    padding: 20px;
}

.popup_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.popup_input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
span{
    cursor: pointer;
}
.count_nbr_pizza{
    cursor: pointer;
    padding: 3%;
}
.header_count_nbr_pizza{
    cursor: pointer;
    padding: 4%;
}
span:hover{
    background-color: #ccc;
}

/* popup  */
.popup_false{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  
  .popup_container_false{
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 5px;
      padding: 50px;
  }
  
  .popup_content_false{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 20px;
  }

  canvas {
    background: rgb(95, 93, 93);
    display: block;
    margin: 0 auto;
  }