
/* PARENT CONTAINER of RIGHT & LEFT SIDE  */

.all_container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

/* ---------- RIGHT SIDE ---------- */

.search-and-display{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

.div-search-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
    font-size: 20px;

}

.div-input-search-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 90%;
}

.input-search-bar{
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 40px;
}
.div-display-pizzas-ingredient{
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 95%;
    height: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.div-card-pizzas-ingredient{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 29%;
    height: fit-content;
}
.div-display-pizzas{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.div-display-ingredient{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.filtre-search-bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 20%;
    height: 80%;
}
.rows{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}



/* ---------- RIGHT SIDE ---------- */

.form_pizza{
    width: 30%;
    background-color: brown;
}

.pizza-selected{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 65%;
}

.pizza-selected-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
}

.display-selected-pizza{
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 97%;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 100%;
}

.display-pizza{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

.save-pizza-selected{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 10%;
}

.btn-save-pizza-selected{
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-top: 5%;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
}

.span-button{
    font-size: 20px;
    /* make me a border  */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
    cursor: pointer;
    
}
.btn-save-pizza-selected:hover{
    background-color: #ccc;   
}
.pizza-quantity-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    border: 0.01rem solid #ccc;
}

.btn_id{
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

}

.btn_id:hover{
    background-color: #ccc;   
}

.div-display-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 70%;
}


.popup{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup_container{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    padding: 20px;
}

.popup_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.popup_input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
}
.popup_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
button{
    width: 100px;
    height: 30px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin: 5px;
}

button:hover{
    background-color: #ccc;
}
