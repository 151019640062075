h1{
    text-align: center;
    /* font-family:Arial, Helvetica, sans-serif; */
    padding-top: 1%;
    color: rgb(206, 36, 36);
}

.c{
    display: block;
}

.bg-pizza{
    background-image: url(../../asset/pizza-ge61362414_1920.jpg);
    width: 100%;
    height: 400px;
    background-position: center;
}

.bg-pizza p{
    color:#fff;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
}

.main-content{
    padding-top: 2.5%;
    display: flex;
    justify-content: center;
    padding-bottom: 2.5%;
}

.group-content{
    border-style: solid;
    border-color: red;
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: space-between;
}

.single-content{
    display: flex;
    flex-direction: column;
}